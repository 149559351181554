import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ContactView } from './views/ContactView';
import { HomeView } from './views/HomeView';

function App() {
    return (
                <Routes>
                    <Route path="/" element={<HomeView/>}></Route>
                    <Route path="/kontakt" element={<ContactView/>}></Route>
                </Routes>
    );
}

export default App;