import React from "react";
import { Link } from "react-router-dom";

interface Props {
    text: string;
    src: string;
}

export const BtnGreen = (props: Props) => {

    return (
        <div className="flex">
            <Link to={props.src} className="bg-[#0B7763] px-12 py-4 rounded-xl text-white font-afacad md:text-2xl" >
                {props.text}
            </Link>
        </div>
    );
};