import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <nav className="bg-[#F1F3F3] py-4 px-4 text-[#282828]">
            <div className="container mx-auto flex justify-between items-center h-22">
                {/* Logo */}
                <div className="flex items-center h-full">
                    <div className="flex items-center h-14 w-14 mr-2">
                        <img src="/images/logoHs.svg" alt="HS-POL logo" />
                    </div>
                    <div className="flex flex-col items-center">
                        <h1
                            className="text-xl md:text-2xl sm:text-3xl font-rethinksans font-extrabold"
                        >
                            HS-POL GROUP
                        </h1>
                    </div>
                </div>

                {/* Przycisk menu na urządzeniach mobilnych */}
                <div className="md:hidden">
                    <button
                        onClick={toggleMenu}
                        className="text-xl focus:outline-none"
                    >
                        {isOpen ? <XMarkIcon className="h-6 w-6 m-4" /> : <Bars3Icon className="h-6 w-6 m-4" />}
                    </button>
                </div>

                {/* Menu na większych ekranach */}
                <div className="hidden md:flex md:items-center font-afacad">
                    <Link
                        to="/"
                        className="hover:text-gray-300 px-4 py-2"
                        onClick={closeMenu} // Dodajemy obsługę zamknięcia menu po kliknięciu
                    >
                        Strona główna
                    </Link>
                    <Link
                        to="/kontakt"
                        className="hover:text-gray-300 px-4 py-2"
                        onClick={closeMenu} // Dodajemy obsługę zamknięcia menu po kliknięciu
                    >
                        Kontakt
                    </Link>
                </div>
            </div>

            {/* Overlay menu na urządzeniach mobilnych */}
            {isOpen && (
                <div className="md:hidden absolute left-0 right-0 top-24 bg-[#F1F3F3] font-afacad">
                    <div className="">
                        <Link to="/"
                              className="hover:text-gray-300 block p-4 text-lg"
                              onClick={closeMenu} // Dodajemy obsługę zamknięcia menu po kliknięciu
                        >
                            Strona główna
                        </Link>
                        <Link to="/kontakt"
                              className="hover:text-gray-300 block p-4 text-lg"
                              onClick={closeMenu} // Dodajemy obsługę zamknięcia menu po kliknięciu
                        >
                            Kontakt
                        </Link>
                    </div>
                </div>
            )}
        </nav>
    );
};

