import React from "react";
import {ClockIcon, DevicePhoneMobileIcon, EnvelopeIcon } from '@heroicons/react/24/outline'

export const TopBar = () => {
    return <div className="hidden w-full md:flex justify-between bg-[#0B7763] px-4 text-white font-afacad">
        <div className="flex h-full py-4 container mx-auto">
            <div className="flex items-center justify-center mr-4 h-full py-0">
                <DevicePhoneMobileIcon className="h-5 w-5 text-white mr-2" />
                <a href="tel:728-428-111" className="text">728-428-111</a>
            </div>
            <div className="flex items-center justify-center mr-4 h-full py-0">
                <EnvelopeIcon className="h-5 w-5 text-white mr-2" />
                <a href="mailto:hs-zlomnica@gmail.com" className="text">hs-zlomnica@gmail.com</a>
            </div>
            <div className="flex items-center justify-center h-full py-0">
                <ClockIcon className="h-5 w-5 text-white mr-2" />
                <p className="text">pn-pt <strong>6-17</strong>, sob: <strong>7-14</strong></p>
            </div>
        </div>
    </div>
}