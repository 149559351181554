import React from "react";
import {BanknotesIcon, CubeTransparentIcon, HomeIcon} from "@heroicons/react/24/outline";

export const WhyMe = () => {
    return (
        <div className="w-full pt-8 mt-8 pb-8 md:pb-16 bg-[#0B7763] text-white">

            <div className="container mx-auto">
                <div className="flex justify-center mt-8 pb-8 md:pb-12">
                    <h3 className="font-rethinksans font-bold text-4xl md:text-8xl border-l-4 md:border-l-8 border-[#fff] pl-2">Dlaczego my?</h3>
                </div>

                <div className="mt-4 md:flex md:w-full md:justify-center md:items-start">
                    <div className="flex flex-col items-center md:w-[30%]">
                        <div className="text-white bg-white rounded-full w-40 h-40 flex justify-center items-center">
                            <BanknotesIcon className="h-16 w-16 text-[#0B7763]"/>
                        </div>
                        <h3 className="font-rethinksans font-extrabold text-3xl mt-6 w-[80%] text-center">Natychmiastowa zapłata</h3>
                        <p className="font-afacad text-base mt-4 w-[70%] text-center color-[#E8FDF9] text-lg">Wybierając HS-POL GROUP masz pewność, że pieniądze otrzymasz <b>natychmiast.</b> Bez czekania, bez niepewności, od ręki.</p>
                    </div>

                    <div className="flex flex-col items-center mt-16 md:mt-0 md:w-[30%]">
                        <div className="text-white bg-white rounded-full w-40 h-40 flex justify-center items-center">
                            <CubeTransparentIcon className="h-16 w-16 text-[#0B7763]"/>
                        </div>
                        <h3 className="font-rethinksans font-extrabold text-3xl mt-6 w-[80%] text-center">Konkurencyjne ceny</h3>
                        <p className="font-afacad text-base mt-4 w-[70%] text-center color-[#E8FDF9] text-lg">Nasze <b>konkurencyjne ceny</b> to gwarancja, że za Twój złom otrzymasz najlepszą ofertę na rynku. Nie trać czasu na szukanie lepszych warunków – u nas dostaniesz najlepszą zapłatę!</p>
                    </div>

                    <div className="flex flex-col items-center mt-16 md:mt-0 md:w-[30%]">
                        <div className="text-white bg-white rounded-full w-40 h-40 flex justify-center items-center">
                            <HomeIcon className="h-16 w-16 text-[#0B7763]"/>
                        </div>
                        <h3 className="font-rethinksans font-extrabold text-3xl mt-6 w-[80%] text-center">Przyjeżdżamy po złom</h3>
                        <p className="font-afacad text-base mt-4 w-[70%] text-center color-[#E8FDF9] text-lg">Zapomnij o transporcie – my przyjeżdżamy po Twój złom! Nasza ekipa z łatwością odbierze materiały z Twojego miejsca, co oszczędzi Ci czas i trudy. Liczy się dla nas Twój komfort i wygoda.</p>
                    </div>
                </div>

            </div>

        </div>
    )
}