import React from 'react';
import '../App.css';
import 'swiper/css/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';
import './Carousel.css';

SwiperCore.use([Autoplay, Pagination]);

export const Carousel = () => {
    const renderBullet = (index: number, className: string) => {
        return '<span class="' + className + '">' + '</span>';
    };

    return (
        <div className="container mx-auto pl-8 pr-8 pb-8 md:pt-12">
            <div className="flex justify-center pb-8 md:pb-12">
                <h3 className="font-rethinksans font-bold text-4xl md:text-8xl border-l-4 md:border-l-8 border-[#0B7763] pl-2 text-[#282828]">Co kupujemy?</h3>
            </div>
            <div className="swiper-wrapper pt-8">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    }}
                    autoplay={{ delay: 4000 }}
                    pagination={{
                        el: '.my-custom-pagination-div',
                        clickable: true,
                        renderBullet: renderBullet,
                    }}
                    className="mySwiper"
                >
                    <SwiperSlide className="Slide Slide--1">
                        <div className="Slider">
                            <div className="Slider--Box-text">
                                <h3 className="Slider--Title font-rethinksans font-extrabold">Metale kolorowe</h3>
                                <p className="Slider-text text-base font-afacad">W naszej firmie skupujemy metale kolorowe, takie jak: miedź, cyna, cynk, ołów czy aluminium. Przyjmiemy Twoje stare żaluzje, klamki, a nawet mosiężne lampy.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="Slide Slide--2">
                        <div className="Slider">
                            <div className="Slider--Box-text">
                                <h3 className="Slider--Title font-rethinksans font-extrabold">Silniki elektryczne</h3>
                                <p className="Slider-text text-base font-afacad">Masz do oddania pozostałości z maszyn? Chętnie przyjmiemy rożnego rodzaju silniki elektryczne, których już nie potrzebujesz.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="Slide Slide--3">
                        <div className="Slider">
                            <div className="Slider--Box-text">
                                <h3 className="Slider--Title font-rethinksans font-extrabold">Wiązka samochodowa</h3>
                                <p className="Slider-text text-base font-afacad">Po licznych modyfikacjach pojazdu została Ci spora ilość kabla samochodowego? Będziemy wiedzieć co z nim zrobić.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="Slide Slide--4">
                        <div className="Slider">
                            <div className="Slider--Box-text">
                                <h3 className="Slider--Title font-rethinksans font-extrabold">Kabel izolacyjny</h3>
                                <p className="Slider-text text-base font-afacad">W gospodarstwach domowych często zostaje duża ilość kabla izolacyjnego. Jeśli i Ty posiadasz go w nadmiarze, zarób na nim.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="Slide Slide--5">
                        <div className="Slider">
                            <div className="Slider--Box-text">
                                <h3 className="Slider--Title font-rethinksans font-extrabold">Puszki</h3>
                                <p className="Slider-text text-base font-afacad">Liczyłeś kiedyś ile puszek ulubionego napoju kupujesz w ciągu miesiąca? Teraz w prosty sposób możesz na nich zarobić.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="Slide Slide--6">
                        <div className="Slider">
                            <div className="Slider--Box-text">
                                <h3 className="Slider--Title font-rethinksans font-extrabold">Złom stalowy</h3>
                                <p className="Slider-text text-base font-afacad">Chcesz oddać zbędne kable, pręty lub gwoździe? Przyjmiemy przedmioty przemysłowe lub użytkowe, które nie nadają się już do naprawy.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="my-custom-pagination-div pt-6"/>
        </div>
    );
};