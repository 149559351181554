import React from "react";

import {Link} from "react-router-dom";


export const ContactView = () => {
    return     <div className="w-full items-center text-[#282828]">

        <div className="container mx-auto">
            <div className="flex justify-start pl-8 mt-8 pb-8 md:pb-12 md:mt-16 md:mb-16">
                <h3 className="font-rethinksans font-bold text-4xl md:text-8xl border-l-4 md:border-l-8 border-[#0B7763] pl-2">Kontakt</h3>
            </div>

            <div className="flex flex-col md:flex-row pl-8">
                <div className="flex flex-col">
                    <h3 className="font-afacad font-bold text-2xl">Godziny otwarcia</h3>
                    <div className="flex font-afacad text-xl mt-2">
                        <p>pon-pt:</p>
                        <p className="ml-2 font-bold">6-17</p>
                    </div>
                    <div className="flex font-afacad text-xl mt-2">
                        <p>sobota:</p>
                        <p className="ml-2 font-bold">7-14</p>
                    </div>
                </div>
                <div className="flex flex-col mt-4 md:mt-0 md:ml-16 font-afacad">
                    <h3 className="font-bold text-2xl">Kontakt</h3>
                    <a href="tel:728-428-111" className="text-xl mt-2">728-428-111</a>
                    <a href="mailto:hs-zlomnica@gmail.com" className="text-xl mt-2">hs-zlomnica@gmail.com</a>
                    <p className="text-xl mt-2">ul.Hołdunowska 79</p>
                    <p className="text-xl mt-2">43-140, Lędziny</p>
                </div>
            </div>

            <div className="flex justify-start pl-8 mt-8 pb-8 md:pb-12 md:mt-16 md:mb-16">
                <h3 className="font-rethinksans font-bold text-4xl md:text-8xl border-l-4 md:border-l-8 border-[#0B7763] pl-2">Tu jesteśmy</h3>
            </div>

        </div>


        <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2556.352166855634!2d19.13197237694766!3d50.15455000862455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716c779f9af1a91%3A0x1bcb22b6e5d7b727!2sHS-Pol%20Group%20-%20Skup%20z%C5%82omu%20L%C4%99dziny!5e0!3m2!1spl!2sus!4v1702408307362!5m2!1spl!2sus"
            width="100%"
            height="450"
            style={{ border: "0" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
        ></iframe>


    </div>
}

// AIzaSyDCboP-K6PuL39QwfxaRULRj-sD3ukdt7I