import React from "react";

import {Link} from "react-router-dom";
import { BtnGreen } from "../components/BtnGreen";
import { Carousel } from "../components/Carousel";
import { WhyMe } from "../components/WhyMe";

export const HomeView = () => {
    return <div className="flex flex-col w-[100%] items-center text-[#282828]">

        <div className="container mx-auto flex flex-col-reverse p-8 md:flex-row">

            <div className="md:w-[50%]">
                <img
                    src="/images/truck-2.830f61f5de71426e212b.webp"
                    alt="Zdjęcie z złomnicy"
                    className="my-4 md:my-0 rounded-xl"
                />
            </div>

            <div className="md:flex md:flex-col md:justify-center md:ml-8 md:w-[50%]">
                <h2 className="font-rethinksans font-extrabold text-6xl lg:text-8xl">
                    Masz złom do oddania?
                </h2>
                <p className="font-afacad my-4 md:my-12 md:text-2xl ">
                    Z przyjemnością odkupimy Twój złom! <b>HS-POL Group</b> to doświadczony zespół specjalistów zajmujących się skupem różnych rodzajów złomu. Jeśli poszukujesz profesjonalnej firmy, która podejmie się odbioru Twoich odpadów, trafiłeś we właściwe miejsce!
                </p>
                <BtnGreen text="Jak dojechać" src="/kontakt"></BtnGreen>
            </div>

        </div>

        <Carousel/>

        <WhyMe/>

    </div>
}